//Inter web fonts
@font-face {
    font-family: 'Inter Var';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance:"Regular";
    src: url('../fonts/webfonts/Inter/Inter.var.woff2')
        format("woff2")
}


//Additional font feature settings
html {
    font-feature-settings: "cv02","cv03","cv04","cv11";
}