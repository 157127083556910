// Required
@import "./node_modules/bootstrap/scss/functions";
//Import bootstrap overrides variables - theme variables
@import "./custom/variables/variables-theme-teal";

@import "./node_modules/bootstrap/scss/maps";
@import "./node_modules/bootstrap/scss/mixins";
@import "./node_modules/bootstrap/scss/utilities";
@import "./custom/utilities/utilities";
//Import bootstrap scss

@import "./node_modules/bootstrap/scss/bootstrap.scss";

//Custom css variables for Assan Theme
@import "./custom/utilities/root";


//Theme core style import

//Import custom web fonts
@import "./custom/webfonts/webfonts-theme-teal";

@import "./custom/mixins/transition";
@import "./custom/utilities/typography";
@import "./custom/utilities/animations";
@import "./custom/components/components";


// Custom

.heroBrands {
  background: rgba(244, 244, 244, 0.6);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 32px;
}
.logotopHome{
  max-height: 70px !important;
  margin-top: 18px;
}
.logotop{
  max-height: 70px !important;
  margin-top: 2px;
}
